/*====================================
	1. Color
====================================*/
.page-node-type-topics .article {
  max-width: 1000px;
  margin: 20px auto 80px;
}

.page-node-type-topics .article .article-category {
  display: flex;
  margin: 0 4px 4px;
}

.page-node-type-topics .article .article-category .category {
  align-self: flex-end;
  text-align: center;
  font-size: clamp(12px, 3vw, 14px);
  margin: 0 auto 0 0;
  padding: 2px 4px;
}

.page-node-type-topics .article .article-category .category.live {
  color: #FF8C00;
  border: 1px solid #FF8C00;
}

.page-node-type-topics .article .article-category .category.information {
  color: #001976;
  border: 1px solid #001976;
}

.page-node-type-topics .article .article-category .category.special {
  color: #DF1700;
  border: 1px solid #DF1700;
}

.page-node-type-topics .article .article-category .share {
  display: flex;
}

.page-node-type-topics .article .article-title .article-title-txt {
  background: #001976;
  color: #FFFFFF;
  font-size: clamp(20px, 3vw, 28px);
  font-weight: 700;
  line-height: 1.5;
  padding: 8px 12px;
}

.page-node-type-topics .article .article-title .article-title-schedule {
  background: #F5F5F5;
  color: #121212;
  padding: 8px 12px;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
}

.page-node-type-topics .article .article-title .article-title-schedule::before {
  content: "";
  display: inline-block;
  background: url(/img/top/topics_schedule.svg) top left no-repeat;
  width: clamp(18px, 3vw, 24px);
  padding-top: clamp(18px, 3vw, 24px);
  margin-bottom: -2px;
  margin-right: 8px;
}

.page-node-type-topics .article .article-area {
  padding: 12px 12px 40px;
}

.page-node-type-topics .article .lower-btn {
  text-align: center;
}

.page-node-type-topics .article .lower-btn .gotolist {
  display: inline-block;
}

.page-node-type-topics .article .lower-btn .gotolist a {
  font-size: clamp(16px, 3vw, 20px);
  letter-spacing: 0.05em;
  display: flex;
  background: #001976;
  color: #FFFFFF;
  padding: 16px 48px;
  line-height: 1;
  transform: translateX(0);
  box-shadow: 8px 8px 0 rgba(0, 25, 118, 0.5);
  transition: .3s;
  position: relative;
}

.page-node-type-topics .article .lower-btn .gotolist a:hover {
  transform: translateX(8px);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
  transition: .3s;
}

.page-node-type-topics .article .lower-btn .gotolist a::after {
  content: "";
  display: block;
  width: 21px;
  height: 10px;
  border-right: 3px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
  transform: skew(45deg, 0);
  margin-top: 8px;
  margin-left: 16px;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .page-node-type-topics .article .article-category {
    margin: 0 0 4px;
  }
  .page-node-type-topics .article .lower-btn .gotolist a {
    padding: 24px 64px;
  }
}
