@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.page-node-type-topics {
	.article {
		max-width: 1000px;
		margin: 20px auto 80px;
		.article-category {
			display: flex;
			margin: 0 4px 4px;
			.category {
				align-self: flex-end;
				text-align: center;
				font-size: clamp(12px,3vw,14px);
				margin: 0 auto 0 0;
				padding: 2px 4px;
				&.live {
					color: $COLOR_ORANGE;
					border: 1px solid $COLOR_ORANGE;
				}
				&.information {
					color: $COLOR_MAIN;
					border: 1px solid $COLOR_MAIN;
				}
				&.special {
					color: $COLOR_RED;
					border: 1px solid $COLOR_RED;
				}
			}
			.share {
				display: flex;

			}
		}
		.article-title {
			.article-title-txt {
				background: $COLOR_MAIN;
				color: $GRAY_000;
				font-size: clamp(20px,3vw,28px);
				font-weight: 700;
				line-height: 1.5;
				padding: 8px 12px;
			}
			.article-title-schedule {
				background: $GRAY_100;
				color: $GRAY_700;
				padding: 8px 12px;
				font-size: clamp(18px,3vw,24px);
				font-weight: 700;
				&::before {
					content: "";
					display: inline-block;
					background: url(/img/top/topics_schedule.svg) top left  no-repeat;
					width:  clamp(18px,3vw,24px);;
					padding-top:  clamp(18px,3vw,24px);;
					margin-bottom: -2px;
					margin-right: 8px;
				}
			}
		}
		.article-area {
			padding: 12px 12px 40px;
		}
		.lower-btn {
			text-align: center;
			.gotolist {
				display: inline-block;
				a {
					font-size: clamp(16px,3vw,20px);
					letter-spacing: 0.05em;
					display: flex;
					background: $COLOR_MAIN;
					color: $GRAY_000;
					padding: 16px 48px;
					line-height: 1;
					transform: translateX(0);
					box-shadow: 8px 8px 0 rgba(0, 25, 118, 0.5);
					transition: .3s;
					&:hover {
						transform: translateX(8px);
						box-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
						transition: .3s;
					}
					position: relative;
					&::after {
						content: "";
						display: block;
						width: 21px;
						height: 10px;
						border-right: 3px solid $GRAY_000;
						border-bottom: 2.5px solid $GRAY_000;
						transform: skew(45deg, 0);
						margin-top: 8px;
						margin-left: 16px;
					}
				}
			}
		}
	}
}




/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
	.page-node-type-topics {
		.article {
			.article-category {
				margin: 0 0 4px;
			}
			.lower-btn {
				.gotolist {
					a {
						padding: 24px 64px;
					}
				}
			}
		}
	}
}
